



























import { Vue, Component, Model, Prop } from 'vue-property-decorator';
import pick from 'lodash.pick';

import AddressEntry from '../types/AddressEntry';
import AddressModel from '../types/AddressModel';
import Target from '../types/Target';
import DataStore, { defaultStore } from '../data/DataStore';
import { AUTOCOMPLETE_CLOSE_DELAY, ALLOWED_ATTRS } from '../lib/constants';
import getPossibles from '../lib/getPossibles';
import addressEntryToModel from '../utils/addressEntryToModel';
import Autocomplete from './Autocomplete.vue';

const MODEL_EVENT = 'addressinput';

@Component({
	name: 'TypeaheadInput',
	components: {
		Autocomplete
	},
	inheritAttrs: false
})
export default class TypeaheadInput extends Vue {
	// Model
	@Model(MODEL_EVENT, { type: String, default: '' }) value: string;

	// Props
	@Prop({ type: DataStore, default: () => defaultStore }) store: DataStore;
	@Prop({ type: String, required: true }) target: Target; // Name. It's an actual property name in address data.
	@Prop({ type: Number, default: 200 }) autocompleteMaxHeight: number;
	@Prop({ type: Number, default: 5 }) autocompleteItemCount: number;
	@Prop({ type: String, default: '' }) inputClass: string;
	@Prop({ type: Boolean, default: false }) numbered: boolean;

	// Data
	possibles: AddressEntry[] = [];
	selectedIndex: number = -1;

	// Hooks
	created() {
		this.store.setValueProp(this.target, this.value); // Pass initial value into store's value.

		this.store.onValueChange((newModelValue: AddressModel) => {
			const newValue = newModelValue[this.target];

			this.$emit(MODEL_EVENT, newValue);
		});
	}

	// Computed
	get inputType() {
		if (!this.numbered || this.target !== 'zipcode') {
			return 'text';
		}

		return 'number';
	}
	get containerStyle() {
		return {
			position: 'relative'
		};
	}
	get filteredAttrs() {
		return pick(this.$attrs, ALLOWED_ATTRS);
	}
	get inputClassList(): string[] {
		if (!this.inputClass || !this.inputClass.trim()) {
			return null;
		}

		const inputClasses = this.inputClass.split(/\s+/);

		return inputClasses;
	}

	// Methods
	search(query: string) {
		if (query && (query.length > 0)) {
			const { dataSource } = this.store;

			this.possibles = getPossibles(dataSource, this.target, query);

			// If autocomplete list contains items, set index to first item
			if (this.possibles.length > 0) {
				this.selectedIndex = 0;
			}
		} else {
			this.clearAutocomplete();
		}

		this.$emit(MODEL_EVENT, query);
	}
	closeAutocomplete() {
		// Prevent DOM elements destroyed before process complete
		setTimeout(
			() => this.clearAutocomplete(),
			AUTOCOMPLETE_CLOSE_DELAY
		);
	}
	clearAutocomplete() {
		this.selectedIndex = -1;
		this.possibles = [];
	}
	moveUp() {
		if (this.possibles.length === 0) {
			return;
		}

		if ((this.selectedIndex - 1) >= 0) {
			this.selectedIndex -= 1;
		} else {
			this.selectedIndex = this.possibles.length - 1; // Go to last item when at first item
		}
	}
	moveDown() {
		if (this.possibles.length === 0) {
			this.search(this.value); // Start searching when no items

			return;
		}

		if ((this.selectedIndex + 1) < this.possibles.length) {
			this.selectedIndex += 1;
		} else {
			this.selectedIndex = 0; // Go to first item when last item
		}
	}
	pickCurrentItem() {
		if (this.possibles[this.selectedIndex]) {
			const selectedItem: AddressEntry = Object.assign({}, this.possibles[this.selectedIndex]); // Shallow Clone

			this.commitItem(selectedItem);
		}
		this.clearAutocomplete();
	}
	commitItem(item: AddressEntry) {
		const addressModel: AddressModel = addressEntryToModel(item);

		this.store.value = addressModel;
		this.$emit('itemselect', addressModel);
	}
}
